<template>
    <div class="video-management">
        <div class="video-management-content">
            <div class="video-management-header">
                <span>我的成片</span>
                <el-button type="primary" size="small" class="publish-video" @click="publishVideoType">发布视频</el-button>
            </div>
            <div class="select-content">
                <div class="item" v-show="false">
                    <span class="sort-text">平台：</span>
                    <div class="img-item" :class="{current: currentPlatform === selectItem.id}"
                         v-for="selectItem in selectPlatform"
                         :key="selectItem.id"
                         @click="choosePlatform(selectItem.id)">
                        <img class="type-img" :src="selectItem.src">
                        <img class="img-item-selected"
                             src="../../../assets/img/image/platform-selected.png">
                    </div>
                </div>
                <div class="item">
                    <span class="sort-text">账号：</span>
                    <div class="img-item" v-if="selectAccount.length > 0"
                         :class="{current: currentAccount === selectItem.id}"
                         v-for="selectItem in selectAccount"
                         :key="selectItem.id"
                         @click="chooseAccount(selectItem.id)">
                        <img class="type-img" :src="selectItem.avatar">
                        <img class="img-item-selected"
                             src="../../../assets/img/image/platform-selected.png">
                    </div>
                    <div v-if="selectAccount.length === 0">暂无数据</div>
                </div>
                <div class="item">
                    <span class="sort-text">时间：</span>
                    <span class="sort-item" :class="{current: currentTime === 0}" @click="chooseTime(0)">全部</span>
                    <span class="sort-item"
                          :class="{current: currentTime === selectItem.id}"
                          v-for="selectItem in selectTime"
                          :key="selectItem.id"
                          @click="chooseTime(selectItem.id)">
                        {{selectItem.name}}
                    </span>
                </div>
                <div class="item">
                    <span class="sort-text">状态：</span>
                    <span class="sort-item" :class="{current: currentStatus === 0}" @click="chooseStatus(0)">全部</span>
                    <span class="sort-item"
                          :class="{current: currentStatus === selectItem.id}"
                          v-for="selectItem in selectStatus"
                          :key="selectItem.id"
                          @click="chooseStatus(selectItem.id)">
                        {{selectItem.name}}
                    </span>
                </div>
            </div>
            <div class="table-bin">
                <div>
                    <el-button type="text" size="small" class="del-video" @click="delMultipleVideoBtn">
                        <i class="iconfont">&#xe74b;</i>
                        删除（已选择{{selectRowList.length}}个）
                    </el-button>
                    <span v-if="aweme_count.length === 0" style="margin: 0 20px;">视频总数：0</span>
                    <span v-else style="margin: 0 20px;">视频总数：{{aweme_count}}</span>
                    <el-button type="text" size="small" class="del-video" @click="uploadVideoList" v-show="currentAccount"><i class="iconfont">&#xe64d;</i>更新列表</el-button>
                    <el-button type="text" size="small" class="del-video" @click="changeImage" v-show="currentAccount">切换视图</el-button>
                </div>
                <el-input v-model="searchVideoInput" size="small" placeholder="输入关键词" @keyup.enter.native="videoSearch">
                    <el-button slot="append" icon="el-icon-search" @click="videoSearch" class="search-btn"></el-button>
                </el-input>
            </div>
            <el-table :data="myVideoList" style="width: 100%; margin: 10px 0; min-height: calc(100vh - 507px);" size="medium" class="my-video-table"
                      :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#13131B', background: '#F1F0FE'}"
                      :cell-style="{fontSize: '14px',color: '#343441'}" @selection-change="handleMyVideo">
                <el-table-column
                        type="selection"
                        width="50">
                </el-table-column>
                <!--<el-table-column prop="id" align="center" label="序号" :resizable="false" width="50"></el-table-column>-->
                <el-table-column align="left" label="视频信息" :resizable="false" min-width="200">
                    <template slot-scope="scope">
                        <div :class="showBigImg?'':'video-cover'" @click="viewVideoDetail(scope.row)">
                            <img :src="scope.row.cover_url" alt="">
                        </div>
                        <span @click="viewVideoDetail(scope.row)">{{scope.row.desc}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status_value_name" align="center" label="状态" :resizable="false" width="120"></el-table-column>
                <el-table-column prop="video_create_time" align="center" label="发布时间" :resizable="false" sortable></el-table-column>
                <el-table-column prop="play_count" align="center" label="播放数" :resizable="false" width="100"></el-table-column>
                <el-table-column prop="digg_count" align="center" label="点赞数" :resizable="false" width="100"></el-table-column>
                <el-table-column prop="comment_count" align="center" label="评论数" :resizable="false" width="100"></el-table-column>
                <el-table-column prop="forward_count" align="center" label="转发数" :resizable="false" width="100"></el-table-column>
                <el-table-column label="操作" align="center" width="280">
                    <template slot-scope="scope" :resizable="false">
                        <el-button :underline="false" type="text" class="video-btn" @click="unpinkVideoBtn(scope.row)" v-show="scope.row.yike_top === 1">取消置顶</el-button>
                        <el-button :underline="false" type="text" class="video-btn" @click="topVideoBtn(scope.row)" v-show="scope.row.yike_top === 0">置顶</el-button>
                        <!--<el-button :underline="false" type="text" class="video-btn" @click="editVideoBtn(scope.row)">编辑</el-link>-->
                        <el-button :underline="false" type="text" class="del-btn" @click="delVideoBtn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center" style="padding-bottom: 10px;"
                    :current-page="videoPages.currentPageNum"
                    :page-size="videoPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="videoPages.total"
                    @current-change="videoCurrentChange">
            </el-pagination>
            <!--视频详情弹窗-->
            <el-dialog title="视频详情" :visible.sync="dialogVideoDetail" width="800px" class="VideoDetail-dialog" :close-on-click-modal="false" :before-close="handleClose">
                <div class="header">
                    <a class="video-cover" :href="videoDetailData.videoUrl" target="_blank">
                        <img :src="videoDetailData.coverUrl" class="cover">
                        <img src="../../../assets/img/image/play_btn.png" class="play-btn">
                    </a>
                    <div class="user-info">
                        <div class="user-avatar">
                            <div class="avatar">
                                <img :src="videoDetailData.head_img">
                            </div>
                            <div class="users-name">
                                <span class="nickname">{{videoDetailData.nick_name}}</span>
                                <!--<span class="douyin">抖音号：{{videoDetailData.unique_id}}</span>-->
                            </div>
                        </div>
                        <span class="video-name" :title="videoDetailData.video_name">{{videoDetailData.video_name}}</span>
                        <div class="VideoDetail-btn">
                            <div class="play-comment-like">
                                <span class="item">
                                    <i class="iconfont">&#xe718;</i>
                                    {{videoDetailData.play_count}}
                                </span>
                                        <span class="item">
                                    <i class="iconfont">&#xe70d;</i>
                                    {{videoDetailData.comment_count}}
                                </span>
                                        <span class="item">
                                    <i class="iconfont">&#xe71a;</i>
                                    {{videoDetailData.digg_count}}
                                </span>
                            </div>
                        </div>
                        <div class="operating-video">
                            <span style="margin-right: 40px;">发布时间：{{videoDetailData.create_time}}</span>
                            <!--<span>通过时间：2020-08-22  12:22:20</span>-->
                        </div>
                    </div>
                </div>
                <div class="comment-content">
                    <span class="comment-header">评论</span>
                    <el-scrollbar class="comment" :native="false">
                        <div class="comment-item" v-for="item in videoCommentList" v-if="videoCommentList">
                            <div class="commentator">
                                <div class="user-info">
                                    <!--<img :src="item.user_info.avatar_url" alt="">-->
                                    <img src="../../../assets/img/image/user_img.png">
                                    <!--<span class="name">{{item.user_info.screen_name}}</span>-->
                                    <span class="name">好友</span>
                                </div>
                                <div class="content">
                                    <span class="detail">{{item.content}}</span>
                                    <span class="publish-info">
                                        <!--<span style="margin-right: 20px">来自安卓客户端</span>-->
                                        <span>{{item.create_time}}</span>
                                    </span>
                                </div>
                            </div>
                            <div class="reply" v-for="replyItem in item.replay.list">
                                <div class="user-info">
                                    <!--<img :src="replyItem.user_info.avatar_url" alt="">-->
                                    <!--<span class="name">{{replyItem.user_info.screen_name}}</span>-->
                                    <img src="../../../assets/img/image/user_img.png">
                                    <span class="name">作者</span>
                                    <span class="content">{{replyItem.content}}</span>
                                </div>
                                <span class="publish-time">{{replyItem.create_time}}</span>
                            </div>
                            <template style="display: none">
                                <div class="reply" v-for="reply_item in replyList">
                                    <div class="user-info">
                                        <img src="../../../assets/img/image/user_img.png">
                                        <span class="name">作者</span>
                                        <span class="content">{{reply_item.content}}</span>
                                    </div>
                                    <span class="publish-time">{{reply_item.create_time}}</span>
                                </div>
                            </template>

                            <!--<span class="view-more-reply" @click="viewMoreReply(item.comment_id)" v-if="item.replay.has_more && reply_has_more === '1'">展开更多回复</span>-->
                            <!--<span class="no-more-reply" v-if="reply_has_more === '2'">暂时没有更多了</span>-->
                        </div>
                        <span class="view-more" @click="viewMore" v-if="has_more">查看更多</span>
                        <span v-if="!videoCommentList" class="no-data">暂无数据</span>
                    </el-scrollbar>
                </div>
                <div class="reviewing" style="display: none;">
                    <span style="color: #53C1F2;">审核中,请等待......</span>
                </div>
                <div class="did-not-pass" style="display: none;">
                    <div class="time">
                        <span>审核未通过</span>
                        <span>2020-08-10 10:00</span>
                    </div>
                    <div class="detail">
                        <span>已退回</span>
                        <span>本视频不符合活动要求，具体查看活动规则，如有疑问请联系在线客服</span>
                    </div>
                </div>
                <div class="operating-video" style="display: none">
                    <span style="color: #594FEE;">定时发送时间：2020-09-12  12:22:20</span>
                </div>
            </el-dialog>
            <!--发布视频，选择发布方式弹窗-->
            <el-dialog title="发布视频" :visible.sync="dialogPublishVideo" width="600px" class="PublishVideo-dialog VideoDetail-dialog" :close-on-click-modal="false">
                <div class="type">
                    <el-radio v-model="selectPublishType" label="1">本地视频上传</el-radio>
                    <el-radio v-model="selectPublishType" label="2">剪辑库中选择</el-radio>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" class="determine-btn" @click="goPublishVideo">确 定</el-button>
                    <el-button @click="dialogPublishVideo = false">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import _ from "underscore";
    export default {
        name: "VideoManagement",
        data() {
            return {
                //平台选择
                selectPlatform: [
                    {
                        id: 1,
                        name: '抖音',
                        src: require('../../../assets/img/image/platform-1.png')
                    },
                    {
                        id: 2,
                        name: '快手',
                        src: require('../../../assets/img/image/platform-2.png')
                    },
                    {
                        id: 3,
                        name: '哔哩哔哩',
                        src: require('../../../assets/img/image/platform-3.png')
                    },
                    {
                        id: 4,
                        name: '美拍',
                        src: require('../../../assets/img/image/platform-6.png')
                    },
                    {
                        id: 5,
                        name: '西瓜',
                        src: require('../../../assets/img/image/platform-5.png')
                    },
                    {
                        id: 6,
                        name: '火山视频',
                        src: require('../../../assets/img/image/platform-4.png')
                    }
                ],
                currentPlatform: 1,
                //账号选择
                selectAccount: [],
                currentAccount: null,
                //时间筛选
                selectTime: [
                    {
                        id: 1,
                        name: '6小时'
                    },
                    {
                        id: 2,
                        name: '12小时'
                    },
                    {
                        id: 3,
                        name: '3天'
                    }
                ],
                currentTime: 0,
                //状态筛选
                selectStatus: [
                    {
                        id: 1,
                        name: '已通过'
                    },
                    {
                        id: 2,
                        name: '未通过'
                    },
                    {
                        id: 4,
                        name: '进行中'
                    }
                ],
                currentStatus: 0,
                //搜索
                searchVideoInput: '',
                //视频列表
                myVideoList: [],
                //已选的视频集合
                selectRowList: [],
                //是否显示弹窗
                dialogVideoDetail: false,
                dialogPublishVideo: false,
                //选择发布视频方式
                selectPublishType: '1',
                //视频详情
                videoDetailData: {
                    videoUrl: '',
                    coverUrl: '',
                    video_name: '',
                    play_count: '',
                    comment_count: '',
                    digg_count: '',
                    create_time: '',
                    nick_name: '',
                    // unique_id: '',
                    head_img: '',
                },
                //视频评论列表
                videoCommentList: [],
                //分页
                videoPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //用户视频总数
                aweme_count: '',
                //视频id
                aweme_id: '',
                //下一页游标
                cursor: 0,
                //是否显示'查看更多'
                has_more: false,
                //是否显示回复'展开更多回复'
                reply_has_more: '1',
                //评论回复列表
                replyList: [],
                showBigImg: false,
            }
        },
        mounted() {
            this.getPlatformAccount();
        },
        methods: {
            //选择平台
            choosePlatform(id) {
                this.currentPlatform = id;
                this.getPlatformAccount();
            },
            //获取平台账号
            getPlatformAccount() {
                this.$httpStudent.axiosGetBy(this.$api.get_account, {platform_type: this.currentPlatform, type: 1},  (res) => {
                    if (res.code === 200) {
                        this.selectAccount = res.data.data;
                        if (res.data.data.length > 0) {
                            if (!this.currentAccount) {
                                this.currentAccount = res.data.data[0].id;
                                this.getMyVideoList();
                                let params = {
                                    id: this.currentAccount,
                                    platform_type: this.currentPlatform,
                                    type: 1
                                }
                                this.$httpStudent.axiosGetBy(this.$api.get_account, params,  (res) => {
                                    if (res.code === 200) {
                                        this.videoDetailData.head_img = res.data.avatar;
                                        this.videoDetailData.nick_name = res.data.nickname;
                                        this.aweme_count = res.data.aweme_count;
                                    } else {
                                        this.$message.error(res.message);
                                    }
                                }, (err) => {
                                    console.log(err);
                                })
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //选择账号
            chooseAccount(id) {
                this.currentAccount = id;
                if (this.currentAccount) {
                    this.getMyVideoList();
                    let params = {
                        id: this.currentAccount,
                        platform_type: this.currentPlatform,
                        type: 1
                    }
                    this.$httpStudent.axiosGetBy(this.$api.get_account, params,  (res) => {
                        if (res.code === 200) {
                            this.videoDetailData.head_img = res.data.avatar;
                            this.videoDetailData.nick_name = res.data.nickname;
                            this.aweme_count = res.data.aweme_count;
                        } else {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //选择时间
            chooseTime(id) {
                this.currentTime = id;
                this.getMyVideoList();
            },
            //选择状态
            chooseStatus(id) {
                this.currentStatus = id;
                this.getMyVideoList();
            },
            //搜索
            videoSearch() {
                this.getMyVideoList();
            },
            //选择的视频
            handleMyVideo(val) {
                this.selectRowList = val;
            },
            //视频列表
            getMyVideoList() {
                let param = {
                    paging: '1',
                    page: this.videoPages.currentPageNum,
                    pageSize: this.videoPages.eachPageNum,
                    account_id: this.currentAccount
                }
                if (this.currentTime) {
                    if (this.currentTime === 0) {

                    } else if (this.currentTime === 1) {
                        param.time = 6;
                    } else if (this.currentTime === 2) {
                        param.time = 12;
                    } else if (this.currentTime === 3) {
                        param.time = 72;
                    }
                }
                if (this.currentStatus) {
                    param.status = this.currentStatus;
                }
                if (this.searchVideoInput) {
                    param.name = this.searchVideoInput;
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, param, (res) => {
                    if (res.code === 200) {
                        this.myVideoList = res.data.list;
                        this.videoPages.total = res.data.total;
                    } else if (res.code === 400) {
                        this.myVideoList = [];
                        this.$message.error(res.message);
                        setTimeout(()=>{
                            window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                        },1000)
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //更新视频列表
            uploadVideoList() {
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_refreshVideo, {account_id: this.currentAccount}, (res) => {
                    if (res.code === 200) {
                        this.$message.success('更新成功！');
                        this.getMyVideoList();
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            changeImage(){
                this.showBigImg = !this.showBigImg
            },
            //选择发布视频方式
            publishVideoType() {
                this.dialogPublishVideo = true;
            },
            //跳转发布页面
            goPublishVideo() {
                if (this.selectPublishType === '1') {
                    this.$router.push({
                        path: '/student/video/publish',
                        query: {ShortTab : 1}//本地视频上传
                    });
                } else {
                    this.$router.push({
                        path: '/student/video/clip',
                        query: {ShortTab : 2}//剪辑库上传
                    });
                }

            },
            //查看视频详情
            viewVideoDetail(row) {
                this.dialogVideoDetail = true;
                this.viewVideoComment(row.aweme_id);
                //查询单个视频详情
                let param = {
                    account_id: this.currentAccount,
                    item_id: row.aweme_id,
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, param, (res) => {
                    if (res.code === 200) {
                        this.dialogVideoDetail = true;
                        this.videoDetailData.coverUrl = row.cover_url;
                        this.videoDetailData.video_name = row.desc;
                        this.videoDetailData.videoUrl = res.data.video_url;
                        this.videoDetailData.play_count = row.play_count;
                        this.videoDetailData.comment_count = row.comment_count;
                        this.videoDetailData.digg_count = row.digg_count;
                        this.videoDetailData.create_time = row.video_create_time;
                        this.aweme_id = res.data.aweme_id;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            //查看视频评论
            viewVideoComment(aweme_id) {
                let param = {
                    account_id: this.currentAccount,
                    item_id: aweme_id,
                    cursor: this.cursor,
                    count: 10
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_comment, param, (res) => {
                    if (res.code === 200) {
                        this.videoCommentList = res.data.list;
                        this.has_more = res.data.has_more;
                        this.cursor = res.data.cursor;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //取消置顶
            unpinkVideoBtn(row) {
                let param = {
                    id: row.id,
                    need_top: 0
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_top_video, param, (res) => {
                    if (res.code === 200) {
                        this.getMyVideoList();
                        this.$message.success('取消置顶成功！');
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //置顶
            topVideoBtn(row) {
                let param = {
                    id: row.id,
                    need_top: 1
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_top_video, param, (res) => {
                    if (res.code === 200) {
                        this.getMyVideoList();
                        this.$message.success('置顶成功！');
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //编辑
            editVideoBtn(row) {

            },
            //删除
            delVideoBtn(row) {
                this.$confirm('是否删除该视频，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let formData = new FormData();
                    formData.append('account_id', this.currentAccount);
                    let awemeId = [];
                    awemeId.push(row.id)
                    formData.append('item_id', JSON.stringify(awemeId));
                    this.$httpStudent.axiosPost(this.$api.tikhoknew_data_delete, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getMyVideoList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //删除（多选）
            delMultipleVideoBtn() {
                this.$confirm('是否删除选中的视频，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    let arrTmp = this.selectRowList.map(selectItem => selectItem.id);
                    let formData = new FormData();
                    formData.append('account_id', this.currentAccount);
                    formData.append('item_id', JSON.stringify(arrTmp));
                    this.$httpStudent.axiosPost(this.$api.tikhoknew_data_delete, formData, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.message);
                            this.getMyVideoList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            //查看更多
            viewMore() {
                let param = {
                    account_id: this.currentAccount,
                    item_id: this.aweme_id,
                    cursor: this.cursor,
                    count: 10
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_comment, param, (res) => {
                    if (res.code === 200) {
                        if (res.data.list.length > 0) {
                            let arr = res.data.list;
                            arr.forEach(item => {
                                this.videoCommentList.push(item);
                            });
                        }
                        this.has_more = res.data.has_more;
                        this.cursor = res.data.cursor;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            viewMoreReply(comment_id) {
                let params = {
                    account_id: this.currentAccount,
                    item_id: this.aweme_id,
                    cursor: this.cursor,
                    count: 10,
                    comment_id: comment_id
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_reply, params, (res) => {
                    if (res.code === 200) {
                        if (res.data.list.length > 0) {
                            this.replyList = res.data.list;
                            if (res.data.has_more) {
                                this.reply_has_more = '1';
                            } else  {
                                this.reply_has_more = '2';
                            }
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err);
                })
            },

            //分页
            videoCurrentChange(val) {
                this.videoPages.currentPageNum = val;
                this.getMyVideoList();
            },
            handleClose(done) {
                this.dialogVideoDetail = false;
                this.cursor = 0;
                this.videoCommentList = [];
            }
        }
    }
</script>

<style scoped lang="scss">
    .el-breadcrumb {
        margin: 0 0 14px 35px;
        .el-breadcrumb-first {
            ::v-deep .el-breadcrumb__inner {
                color: #BBB9CB;
            }
        }
        .el-breadcrumb-second {
            ::v-deep .el-breadcrumb__inner {
                color: #5F5B74;
            }
        }
    }
    .video-management-content {
        background-color: #fff;
        /*box-shadow: 0 0 16px 1px rgba(11, 11, 28, 0.09);*/
        /*border-radius: 10px;*/
        padding: 35px 0 0 0;
        .video-management-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            border-bottom: 1px solid #EBEBF9;
            margin: 0 35px;
            padding-bottom: 19px;
            .publish-video {
                background-color: #594FED;
                border-color: #594FED;
                border-radius: 15px;
                &:hover {
                    background-color: #463bdc;
                    border-color: #463bdc;
                }
            }
        }
        .select-content {
            padding: 0 35px 0 45px;
            /*padding-left: 10px;*/
            .item {
                display: flex;
                align-items: center;
                padding: 0 10px;
                margin: 20px 0 20px 0;
                color: #666;
            }
            .sort-item {
                display: inline-block;
                padding: 2px 6px;
                margin: 0 5px;
            }
            .sort-item.current, .sort-item:hover {
                background: #9ACC5B;
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
            }
            .img-item {
                background: transparent !important;
                width: 38px;
                height: 38px;
                border-radius: 50% !important;
                margin-right: 20px;
                img.type-img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                img.img-item-selected {
                    display: none;
                    position: relative;
                    top: -15px;
                    left: 27px;
                    width: 14px;
                    height: 14px;
                }
            }
            .current {
                img.img-item-selected {
                    display: block;
                }
            }
        }
        .table-bin {
            padding: 0 45px 0 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ::v-deep .el-button--text {
                color: #666;
                font-size: 14px;
                i {
                    margin-right: 5px;
                }
            }
            ::v-deep .el-input {
                width: 270px;
                .el-input__inner {
                    border-radius: 15px;
                    border: 1px solid #594FEE;
                }
            }
            ::v-deep .el-input-group__append {
                background-color: transparent;
                border: none;
                left: -55px;
            }
        }
        .my-video-table {
            ::v-deep td, th {
                padding: 5px 0;
            }
            ::v-deep .el-table__body-wrapper {
                .el-table__header {
                    th {
                        padding: 5px 0;
                        .cell {
                            margin-left: 5px;
                        }
                    }
                }
                .el-table__body {
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #9ACC5B;
                        border-color: #9ACC5B;
                    }
                    .el-checkbox__inner:hover {
                        border-color: #9ACC5B;
                    }
                    ::v-deep .el-table__row {
                        td:nth-child(2) .cell {
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            cursor: pointer;
                        }
                    }
                }
            }
            .video-cover {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                min-width: 50px;
                height: 40px;
                margin: 0 10px 0 0;
                padding: 0;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .video-btn {
                color: #685DF7;
                margin-right: 10px;
                &:hover {
                    color: #2338E6;
                }
            }
            .del-btn {
                color: #FB0338;
                margin-left: 10px;
                &:hover {
                    color: #CD062E;
                }
            }
        }
        .pages-center {
            ::v-deep .el-pager li {
                background-color: transparent !important;
                width: 24px;
                min-width: 24px;
                height: 24px;
                line-height: 26px;
            }
            ::v-deep .el-pager li:not(.disabled).active {
                background-color: #564CED !important;
                border-radius: 50%;
                color: #fff;
            }
        }
        .VideoDetail-dialog {
            .video-detail {
                /*height: 700px;*/

            }
            ::v-deep .el-dialog {
                border-radius: 10px;
                .el-dialog__header {
                    background-color: #F1F0FE;
                    border-radius: 10px 10px 0 0;
                }
                .el-dialog__body {
                    padding: 20px;
                }
                .el-dialog__footer {
                    text-align: center;
                    .dialog-footer {
                        .el-button {
                            padding: 7px 28px;
                            border-radius: 15px;
                        }
                        .determine-btn {
                            background: #594FEE;
                            border-color: #594FEE;
                            &:hover {
                                background: #2338E6;
                                border-color: #2338E6;
                            }
                        }
                    }
                }
                .header {
                    display: flex;
                    .video-cover {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 300px;
                        height: 200px;
                        margin-right: 20px;
                        cursor: pointer;
                        img.cover {
                           max-width: 100%;
                            max-height: 100%;
                        }
                        img.play-btn {
                            position: absolute;
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .user-info {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        width: calc(100% - 320px);
                        .user-avatar {
                            display: flex;
                            .avatar {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 80px;
                                height: 80px;
                                margin-right: 20px;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                    border-radius: 50%;
                                }
                            }
                            .users-name {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .nickname {
                                    color: #16113F;
                                    font-size: 24px;
                                    font-weight: 500;
                                }
                                .douyin {
                                    color: #16113F;
                                    font-size: 16px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .video-name {
                            margin-top: 20px;
                            color: #16113F;
                            font-size: 18px;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            width: 100%;
                        }
                    }
                }
                .VideoDetail-btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 20px 0;
                    .play-comment-like {
                        color: #594FEE;
                        .item {
                            margin-right: 20px;
                        }
                    }
                    .btn-del-video {
                        color: #FB0360;
                        i {
                            margin-right: 10px;
                            font-size: 14px;
                        }
                    }
                }
                .operating-video {
                    color: #5F5C74;
                }
                .comment-content {
                    margin: 20px 0;
                    border-top: 1px solid #EBEBF9;
                    .comment-header {
                        color: #5F5C74;
                        font-size: 16px;
                        margin: 20px 0;
                        display: block;
                    }
                    .comment {
                        height: 182px;
                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }
                        span.no-data {
                            display: block;
                            text-align: center;
                        }
                        .commentator {
                            margin-bottom: 20px;
                            .user-info {
                                display: flex;
                                align-items: center;
                                margin-left: 20px;
                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }
                                .name {
                                    color: #5F5C74;
                                }
                            }
                            .content {
                                margin-left: 60px;
                                display: flex;
                                flex-direction: column;
                                line-height: 1.6;
                                .detail {
                                    color: #16113F;
                                    font-size: 12px;
                                }
                                .publish-info {
                                    color: #BCB9CB;
                                    font-size: 12px;
                                }
                            }
                        }
                        .reply {
                            margin-left: 60px;
                            margin-bottom: 10px;
                            line-height: 1.6;
                            .user-info {
                                display: flex;
                                align-items: center;
                                img {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    margin-right: 10px;
                                }
                                .name {
                                    color: #5F5C74;
                                    font-size: 12px;
                                    margin-right: 20px;
                                }
                                .content {
                                    color: #16113F;
                                    font-size: 12px;
                                }
                            }
                            .publish-time {
                                color: #BCB9CB;
                                font-size: 12px;
                                margin-left: 30px;
                            }
                        }
                    }
                }
                .did-not-pass {
                    color: #FB0338;
                    .time {
                        margin-bottom: 10px;
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                    .detail {
                        span:nth-child(1) {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .PublishVideo-dialog {
            ::v-deep .el-dialog__body {
                height: 126px;
                .type {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .el-radio__input.is-checked .el-radio__inner {
                         border-color: #DCDFE6;
                        background: transparent;
                    }
                    .el-radio__input.is-checked .el-radio__inner::after {
                        width: 6px;
                        height: 6px;
                        background-color: #9ACC5B;
                    }
                    .el-radio__input.is-checked+.el-radio__label {
                        color: #606266;
                    }
                }
            }
        }
    }
    .view-more {
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
        cursor: pointer;
        &:hover {
            color: #2338E6;
        }
    }
    .no-more {
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }
    .view-more-reply {
        display: block;
        width: 250px;
        font-size: 12px;
        text-align: center;
        padding-bottom: 10px;
        cursor: pointer;
        &:hover {
            color: #2338E6;
        }
    }
    .no-more-reply {
        display: block;
        width: 250px;
        font-size: 12px;
        text-align: center;
        padding-bottom: 10px;
    }
</style>